<template>
  <div class="container">
    <div class="nei_box">
      <div class="img_box">
        <div class="details_wrap">
          <div class="single_img">
            <img src="../../../assets/img/home/kes.png" />
          </div>
          <h2>科技伯乐</h2>
          <p class="tips_word">
            简单4步就可以成为技术经理人，帮助企业的同时，自己也能获得丰厚报酬。
          </p>
        </div>
      </div>
      <div class="right_box">
        <!-- <div class="small_box">
        <el-steps :active="0" align-center>
        <el-step title="填写资料" icon="el-icon-edit"></el-step>
        <el-step title="上传名片" icon="el-icon-document"></el-step>
        <el-step title="人工审核" icon="el-icon-search"></el-step>
        <el-step title="入驻成功" icon="el-icon-circle-check"></el-step>
        </el-steps>
        </div> -->
        <div class="line">
          <el-divider></el-divider>
        </div>
        <div class="form_box">
          <el-form
            :label-position="labelPosition"
            label-width="80px"
            :model="formLabelAlign"
          >
            <el-form-item label="真实姓名">
              <el-input
                v-model="formLabelAlign.title"
                placeholder="一经输入不可修改"
              ></el-input>
            </el-form-item>
            <el-form-item label="职位头衔">
              <el-input
                v-model="formLabelAlign.job"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="任职单位">
              <el-input
                v-model="formLabelAlign.company_name"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱">
              <el-input
                v-model="formLabelAlign.email"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号码">
              <el-input
                v-model="formLabelAlign.phone"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <!--<el-form-item label="常驻城市">-->
            <!--<el-input v-model="formLabelAlign.province" placeholder="请输入"></el-input>-->
            <!--</el-form-item>-->

            <el-form-item label="籍贯" prop="region">
              <div class="diqv_box">
                <v-distpicker @selected="onSelected" province="四川省" city="成都市" area="锦江区" only-province ></v-distpicker>
              </div>
            </el-form-item>
            <el-form-item label="职务描述">
              <el-input
                v-model="formLabelAlign.job_level"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传图片" class="header">
              <el-upload
                :on-change="fn"
                action=""
                list-type="picture-card"
                :on-preview="handlePictureCardPreviewG"
                :on-remove="handleRemoveG"
                :auto-upload="false"
                accept="image/*"
                :class="{ hide: hideUploadG }"
                class="avatar-uploader"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="title_flex">
      <div class="lines"></div>
      <div class="text">技术经理人是什么？</div>
      <div class="lines"></div>
    </div>
    <!--测试-->
    <div class="botom_box">
      <div class="botom_flex">
        <div class="one">
          <div class="nei">
            <div class="icon_box"><i class="el-icon-user"></i></div>
            <div class="title_box">
              <div class="text_one">
                <span class="two">技术经理人是什么？</span>
              </div>
            </div>
              <!-- @dblclick="edit" v-if="!editing"-->
            <div class="content" >
              {{ wenzi }}
            </div>
            <!-- <el-input
              v-if="editing"
              type="textarea"
              v-model="wenzi"
              @blur="save"
            ></el-input> -->
          </div>
        </div>

        <div class="one">
          <div class="nei">
            <div class="icon_box"><i class="el-icon-edit"></i></div>
            <div class="title_box">
              <div class="text_one">
                <span class="two">技术经理人在科汇云获得什么？</span>
              </div>
            </div>
             <!-- @dblclick="edit"  v-if="!editing"-->
            <div class="content">
              {{ wenzi2 }}
            </div>
            <!-- <el-input
              v-if="editing"
              type="textarea"
              v-model="wenzi2"
              @blur="save"
            ></el-input> -->
          </div>
        </div>
        <div class="one">
          <div class="nei">
            <div class="icon_box"><i class="el-icon-menu"></i></div>
            <div class="title_box">
              <div class="text_one">
                <span class="two">我能申请技术经理人?</span>
              </div>
              
            </div>
             <!-- @dblclick="edit"  v-if="!editing"-->
            <div class="content">
              {{ wenzi3 }}
            </div>
            <!-- <el-input
              v-if="editing"
              type="textarea"
              v-model="wenzi3"
              @blur="save"
            ></el-input> -->
          </div>
        </div>
        <div class="one">
          <div class="nei">
            <div class="icon_box"><i class="el-icon-box"></i></div>
            <div class="title_box">
              <div class="text_one">
                <span class="two">我怎么申请成为技术经理人？</span>
              </div>
             
            </div>
            <!-- @dblclick="edit" v-if="!editing" -->
            <div class="content" >
              {{ wenzi4 }}
            </div>
            <!-- <el-input
              v-if="editing"
              type="textarea"
              v-model="wenzi"
              @blur="save"
            ></el-input> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleCompressImg } from "../../../api";
import { manager_add } from "../../common/js/api";
export default {
  name: "apply",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      dialogVisible: false,
      dialogImageUrl: "",
      hideUploadG: false,
    //   editing: false,
      wenzi:
        "技术经理人是源自AUTM（Association of University Technology Managers）中的TM-technology managers。从国外发达国家技术转移领域引入的概念，以科技成果转化为己任，应用专业的知识和实务经验，覆盖整个技术转移流程，以此来促进科技成果的商品化、商业化和产业化，以科技成果转化工作为职业的人。现目前科汇云平台已经入驻了数十位技术经理人，正在筹备科技型技术人员、各行业协会、产业联盟等前期工作，通过企业创新团队提供一系列的专业技能培训和行业资源，让技术经理人成为专业能力和行业资源双优高效人才。",
      wenzi2:
        "1.全站专家、科研团队、专利、成果、科研平台、企业需求免费搜。2.自动获得免费获取查看平台优选的企业需求和科技成果的关键信息。3.个性化定制需求、成果、资讯等信息推送。成就第二职业生涯，赚取知识的价值，沉淀百万积蓄。4.快速积累建立不同领域人脉圈，职场事业不再愁。",
      wenzi3:
        "我们持续招募技术转移机构的从业人员、科技型企业研发人员、技术人员、各行业协会、产业联盟工作人员以及各种民间发明达人等具备一定基础的专业人士加入技术经理人团队；亦或是你曾经深刻体会企业创新之难，有志和我们携手为企业创新做一点事情，也欢迎您的加入。",
      wenzi4:
        "在页面上方填写您的信息并提交，平台的工作人员会与您联系，和您协商线下约见时间和地方，现场跟您交流平台使用方法、合作方式及利益分配等问题，帮助您在最短的时间内赚取第一桶金。",
      labelPosition: "right",
      formLabelAlign: {
        phone: "",
        member_id: JSON.parse(localStorage.getItem("user")).id,
        title: "",
        job: "",
        company_name: "",
        email: "",
        province: "",
        job_level: "",
        img: "", //上传图片
      },
    };
  },
  created() {
  	if(!localStorage.getItem("user")){
  			  this.$router.push({
  			    path: "/login",
  			  });
  	}
  },
  methods: {
    onSelected(data) {
      this.formLabelAlign.province = data.province.code; //省
    },
    fn(file, fileList) {
      this.hideUploadG = fileList.length >= 1;
      handleCompressImg(file.raw).then((result) => {
        console.log(result);
        this.UploadImg(result, 1);
      });
    },
    //将图片地址上传
    async UploadImg(file) {
      let formData = new FormData();
      formData.append("file", file);
      await this.$axios
        .post("http://hout.kehui.cloud/api/demand/uploadImg", formData, {
          headers: { "Content-Type": '"multipart/form-data"' },
        })
        .then((res) => {
          console.log(res.data.data);
          this.formLabelAlign.img = res.data.data;
        });
    },
    handleRemoveG(file, fileList) {
      this.hideUploadG = fileList.length >= 1;
    },
    handlePictureCardPreviewG(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    onSubmit() {
      this.apply();
    },

    apply() {
      manager_add({
        data: this.formLabelAlign,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.formLabelAlign = {};
            this.dialogImageUrl = "";
            // location.reload();
          }
        })
        .catch((err) => {});
    },

    // edit: function () {
    //   this.editing = true;
    //   this.$nextTick(function () {
    //     this.$els.input.focus();
    //   });
    // },
    // save: function () {
    //   this.editing = false;
    // },
  },
};
</script>

<style scoped lang="less">
/deep/.distpicker-address-wrapper select {
     width: 100%;
    font-size: 14px;
    padding: 0px 10px!important;
}
.details_wrap {
  display: inline-block;
  line-height: 1.2;
  vertical-align: middle;
  position: relative;
  .tips_word {
    width: 266px;
    margin: 44px auto 0;
    font-size: 20px;
    text-align: left;
    word-wrap: break-word;
    word-break: break-all;
  }
  h2 {
    font-size: 32px;
    margin-top: 5px;
    text-shadow: 1px 2px 2px #124497;
  }
  h2:after {
    content: "";
    display: block;
    margin: 14px auto 10px;
    -webkit-box-shadow: 1px 2px 2px #124497;
    box-shadow: 1px 2px 2px #124497;
    width: 80px;
    height: 4px;
    background-color: #fff;
  }

  .single_img {
    width: 140px;
    height: 140px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
/deep/.distpicker-address-wrapper select {
  padding: 0px;
}
/deep/.el-form-item__content {
  line-height: 0px;
}
/deep/.hide .el-upload--picture-card {
  display: none;
}
/deep/.el-textarea__inner {
  height: 220px;
}
.form-control {
  height: 100px;
}
/deep/.el-icon-user {
  font-size: 70px;
  color: #3892eb;
}
/deep/.el-icon-edit {
  font-size: 70px;
  color: #3892eb;
}
/deep/.el-icon-box {
  font-size: 70px;
  color: #3892eb;
}
/deep/.el-icon-menu {
  font-size: 70px;
  color: #3892eb;
}
/deep/.el-step__icon-inner {
  font-size: 30px;
}
.title_flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .text {
    font-size: 48px;
    color: #3892eb;
    font-weight: 600;
  }
  .lines {
    margin: 100px;
    width: 60px;
    height: 1px;
    background-color: #3892eb;
  }
}
/deep/.el-step__title.is-process {
     color: #3892eb;
}
/deep/.el-steps {
  width: 500px;
  margin: 0 auto;
}
.container {
  background-color: #f8f9fa;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 135px;
  .nei_box {
    display: flex;
    background-color: #fff;
    width: 1200px;
    margin: 0 auto;
    height: 760px;

    .img_box {
      z-index: 99;
      background-color: rgb(53, 112, 205);
      background-image: url("~@/assets/img/home/ji_shu.png");
      background-size: 100% 100%;
      width: 460px;
      height: 760px;
      line-height: 600px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .right_box {
    .small_box {
      width: 500px;
      margin: 15px 30%;
    }
  }
  .form_box {
    width: 550px;
    margin: 15px 20%;
  }
  .line {
    width: 650px;
    margin: 30px 10%;
  }
  .botom_box {
    width: 1200px;
    height: 430px;
    background-color: #fff;
    margin: 0 auto 60px auto;
    .botom_flex {
      display: flex;
      .one {
        width: 300px;
        height: 400px;
        .nei {
          width: 90%;
          height: 90%;
          margin: 7% auto;
          .title_box {
            display: flex;
            font-weight: 600;
            font-size: 18px;
            margin: 15px 0px;
            .text_one {
              width: auto;
              margin: 0;
              padding-bottom: 10px;
              border-bottom: 3px solid #3892eb;
              .one {
                margin: 0;
                padding: 0;
                color: #3892eb;
              }
              .two {
                margin-left: -3px;
              }
            }
          }
          .content {
            color: rgba(105, 105, 105, 100);
            font-size: 14px;
            line-height: 25px;
            //两行
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 10;
            line-clamp: 10;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
	.container{
		margin-top: 15rem;
	}
	.w{
		width: 98%;
	}
	.container .nei_box .img_box{
		display: none;
	}
	/deep/.el-form-item__label{
		width: 8rem !important;
		font-size: 1.6rem;
		padding-top: 0.6rem;
	}
	.container .line{
		display: none;
	}
	.container .nei_box{
		width: 100%;
		height: auto;
	}
	.container .form_box{
		width: 100%;
		margin: 3rem 0;
		height: auto;
	}
	.right_box{
		width: 100%;
	}
	/deep/.el-form-item__content{
		margin-left: 9rem !important;
	}
	/deep/.el-input{
		width: 90%;
	}
	/deep/.el-input__inner{
		height: 3rem;
	}
	/deep/.distpicker-address-wrapper select{
		height: 3rem;
		font-size: 1.6rem;
		width: 90%;
	}
	/deep/.el-button{
		font-size: 1.6rem;
	}
	.container .botom_box{
		width: 100%;
		height: auto;
	}
	.container .botom_box .botom_flex{
		flex-wrap: wrap;
	}
	.container .botom_box .botom_flex .one{
		width: 48%;
		height: auto;
	}
	.container .botom_box .botom_flex .one .nei .title_box{
		font-size: 1.5rem;
	}
	.container .botom_box .botom_flex .one .nei .content{
		font-size: 1.4rem;
		line-height: 2.5rem;
	}
}
</style>